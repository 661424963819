<template>
  <!-- prettier-ignore -->
  <div class="modal-product modal fade hide-scrollbar"
    id="modalProduct" tabindex="-1"
    aria-labelledby="modalProduct"
    aria-hidden="true"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove">
    <div class="modal-dialog modal-dialog-centered
      justify-content-center
      m-0 m-md-auto">
      <div class="modal-content" v-if="product !== null">
        <div class="modal-body p-0 d-flex flex-column flex-lg-row justify-content-between justify-content-lg-center">
          <div class="modal-image row align-items-center
            px-5 px-lg-0 pt-lg-0 col-lg-6 flex-grow-1 flex-lg-grow-0" :class="{'d-lg-none': !prod.image}">
            <div class="product-gallery px-0">
              <!-- <VueSlickCarousel  v-bind="settings"> -->
                <div v-if="prod.image" class="product-image">
                  <img :src="prod.image" :alt="prod.name" />
                </div>
              <!-- </VueSlickCarousel> -->
            </div>
          </div>  <!-- End modal-image -->

          <div class="modal-details position-relative
            d-flex align-items-end col-lg-4">
            <div class="product-details position-relative
              align-content-between flex-grow-1 row p-2 p-lg-1">
              <button type="button" class="btn position-absolute p-0" data-bs-dismiss="modal" aria-label="Close">
                <span class="material-symbols-outlined">cancel</span>
              </button>

              <div class="body" :class="{'no-image': !prod.image}">
                <h2 class="producto-title subtitle pt-2 mb-1">
                  {{ prod.name }}
                </h2>
                

          <ul class="d-flex flex-wrap m-0">
            <li class="tag rounded-pill" v-for="(t, i) in prod.tags" :key="i"><span style="margin-right: 4px;" :class="t.icon"></span>{{ t.name }}</li>
          </ul>
                <div class="producto-desc">
                  <p class="pt-1" >{{prod.description}}</p>
                  
                  <ul>
                    <li class="mb-3" v-for="g, index in prod.variant_groups" :key="index" :class="!g.variants.length?'d-none':''">
                      <p>{{ g.name }}</p>
                      <small v-for="v, i in g.variants" :key="i" :class="!v.enabled && v.enabled !== undefined?'d-none':'d-block'">
                        {{ v.name }} {{v.price?`$${v.price}` : ''}}
                      </small>
                    </li>
                  </ul>
                  <!-- <ul>
                    <li v-for="g, i in prod.variant_groups" :key="i">
                      <span v-if="g.enabled || g.enabled === undefined">
                        {{g.name}} <span v-if="g.price !== null">${{variant.price}}</span>
                      </span>
                    </li>
                  </ul> -->
                </div>
              </div>

              <div class="footer pt-2 d-flex justify-content-between align-items-center">
                <h5 class="producto-price headline" style="font-size: 2.8em;">
                  ${{ prod.price }}
                </h5>
                <div class="swipe text-center d-lg-none">
                    <span class="material-icons d-block mb-1">swipe</span> Swipe
                </div>
              </div>
            </div>
          </div>  <!-- End modal-details -->
        </div>
      </div>  <!-- End modal-content -->
    </div>
  </div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "ProductModal",
  components: {
    // VueSlickCarousel,
  },
  computed: {
    recProds() {
      let products = [];

      if (this.prodByCat.length) {
        const family = this.prodByCat.find((f) => f.id === this.product.catId);

        family.categories.forEach((c) => {
          products = products.concat(c.recommended);
        });
      }

      return products;
    },
    prod: {
      get() {
        if (this.result.length === 0 && !this.product.recommended) {
          if (this.old !== this.product && this.product.index === undefined) {
            // Get position of category, subcategory, product
            this.prodByCat
              .find((item, i) => {
                if (item.id === this.product.catId) {
                  // Save category position
                  this.ci = i;
                }
                // Return category json
                return item.id === this.product.catId;
              })
              .categories.find((item, i) => {
                if (item.id === this.product.subcatId) {
                  // Save subcategory position
                  this.sci = i;
                }
                // Return subcategory json
                return item.id === this.product.subcatId;
              })
              .products.find((item, i) => {
                if (item.id === this.product.prodId) {
                  // Save product position
                  this.pi = i;
                }
                // Save ids
                this.old = this.product;
              });
          }

          // console.log("cat", this.ci, "subcat", this.sci, "prod", this.pi);

          // Return product clicked in ProductList for use in modal
          return this.prodByCat[this.ci].categories[this.sci].products[this.pi];
        } else if (this.product.recommended) {
          // this.product.index = this.recProds.findIndex(
          //   (p) => p.id === this.product.prodId
          // );
          return this.recProds[this.product.index];
        } else {
          // Return product clicked in SearchResult for use in modal
          return this.result[this.product.index];
        }
      },
    },
    index: {
      get() {
        return this.product;
      },

      set(value) {
        this.product = value;
      },
    },
  },
  data() {
    return {
      // Index for Cat, SubCat, Prod
      ci: 0,
      sci: 0,
      pi: 0,
      old: null,
      // settings: {
      //   dots: true,
      //   arrows: false,
      //   dotsClass: "slick-dots custom-dot-class",
      //   centerMode: true,
      //   centerPadding: "0px",
      //   cssEase: "linear",
      //   slidesToShow: 1,
      //   slidesToScroll: 1,
      // },
    };
  },
  props: {
    prodByCat: Array,
    result: Array,
    // Get ids fo cat, sCat, prod
    product: Object,
  },
  methods: {
    handleTouchStart(evt) {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }
      let xUp = evt.touches[0].clientX;
      let yUp = evt.touches[0].clientY;

      let xDiff = this.xDown - xUp;
      let yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          // Next product of ProductList
          if (this.result.length === 0 && !this.product.recommended) {
            // Product next position
            if (
              this.pi <
              this.prodByCat[this.ci].categories[this.sci].products.length - 1
            ) {
              this.pi += 1;
            } else {
              // Subcategory next position and reset product position
              if (this.sci < this.prodByCat[this.ci].categories.length - 1) {
                this.sci += 1;
                this.pi = 0;
              } else {
                // Category next position and reset subcategory and product position
                if (this.ci < this.prodByCat.length - 1) {
                  this.ci += 1;
                  this.pi = 0;
                  this.sci = 0;
                }
              }
            }
            // Next product of SearchResult
          } else if (this.product.recommended) {
            if (this.product.index < this.recProds.length - 1) {
              this.product.index += 1;
            }
          } else {
            if (this.product.index < this.result.length - 1) {
              this.product.index += 1;
            }
          }
        } else {
          // Prev product of ProductList
          if (this.result.length === 0 && !this.product.recommended) {
            // Product prev position
            if (this.pi > 0) {
              this.pi -= 1;
            } else {
              // Subcategory prev position and set product last position
              if (this.sci > 0) {
                this.sci -= 1;
                this.pi =
                  this.prodByCat[this.ci].categories[this.sci].products.length -
                  1;
              } else {
                // Category prev position and set subcategory and product last position
                if (this.ci > 0) {
                  this.ci -= 1;
                  this.sci = this.prodByCat[this.ci].categories.length - 1;
                  this.pi =
                    this.prodByCat[this.ci].categories[this.sci].products
                      .length - 1;
                }
              }
            }
            // Prev product of SearchResult
          } else if (this.product.recommended) {
            if (this.product.index > 0) {
              this.product.index -= 1;
            }
          } else {
            if (this.product.index > 0) {
              this.product.index -= 1;
            }
          }
        }
      }
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-dialog-centered {
    min-height: calc(100% - 0.5rem);
  }
  &-content {
    background: transparent;
  }
  &-body {
    height: 100vh;
    &::-webkit-scrollbar {
      display: none;
    }
    .swipe {
      color: var(--secondary-font-cl);
      right: 16px;
      bottom: 16px;
      font-size: $ft-xxs;
    }
    .tag {
      background-color: var(--primary-bg);
      color: var(--secondary-font-cl);
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding: 4px;
      margin-bottom: 4px;
      margin-right: 4px;
    }
  }
  &-image {
    // height: 65%;
    overflow: auto;
  }
  &-details {
    // height: 35%;
    .product-details {
      height: fit-content;
      min-height: 150px;
      background: var(--secondary-bg);
      border-radius: 25px 25px 0 0;
      .body {
        max-height: 270px;
        overflow: hidden;
      }
      .no-image {
        max-height: 500px;
      }
      h2 {
        font-size: $ft-lg;
      }
      .producto-desc {
        overflow: auto;
        height: calc(100% - 44px);
        p {
          white-space: pre-line;
        }
        ul {
          font-size: $ft-sm;
          span {
            font-weight: 600;
          }
        }
        &::-webkit-scrollbar-track {
          background-color: var(--secondary-bg);
        }
        &::-webkit-scrollbar-thumb {
          background: var(--primary-bg);
        }
      }
      button {
        color: var(--primary-font-cl);
        width: 24px;
        top: 8px;
        right: 16px;
        font-size: 24px;
      }
    }
  }
}
@media (min-width: $desktop-width) {
  .modal {
    &-body {
      height: initial;
    }
    &-dialog {
      max-width: 1200px;
    }
    &-content {
      width: 70%;
    }
    &-details {
      height: initial;
      .product-details {
        border-radius: 0px;
        height: 100%;
        .body {
          max-height: 335px;
        }
        .no-image {
          max-height: 500px;
        }
      }
    }
  }
}
</style>